html,body{
  font-family: 'Cormorant Garamond', serif;
  user-select: none;
}

img{
  -webkit-user-drag: none; 
}

a{
  color:rgb(105, 188, 216);
}

.first{
  display: flex;
  flex-direction: row;
}

.second{
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index:5;
}

#main{
  height:1200px;
  width:80vw;
  position: relative;
  overflow: hidden;
  z-index:1;
  box-shadow: 0 0 15px 10px #606060;
}

#back{
  width: 1600px;
  height: 100%;
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
}

#leftarrow{
  top:10%;
  height:800px;
  line-height: 800px;
  font-size:80px;
  color:#909090;
  position:absolute;
  z-index:6;
  opacity:0;
  transition: opacity 1s;
}

#rightarrow{
  top:10%;
  height:840px;
  line-height: 800px;
  right:0%;
  font-size:80px;
  color:#909090;
  position:absolute;
  z-index:6;
  opacity:0;
  transition: opacity 1s;
}

#leftarrow:hover,#rightarrow:hover{
  color:#FFFFFF;
  cursor: pointer;
}


div h1{
  font-family: 'Cormorant Garamond', serif;
  font-size:80px;
  font-weight:400;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  top:1.5%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  color:#303030;
  background:rgba(255, 255, 255, 0.6);
}

#email{
  display:none;
}

#menubutton{
  position: absolute;
  top: 1%;
  right:1%;
  z-index:5;
  color: #303030;
  font-size: 25px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  opacity:0;
}

#menubutton:hover{
  background-color: #c1b4e0;
  cursor: pointer;
}

#intro{
  top: 40%;
  left: 60%;
  transform: translateX(-50%);
  width: 50%;
  padding: 1.5%;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  position:absolute;
  font-size:35px;
  font-family: 'Cormorant Garamond', serif;
  font-weight:500;
  text-align: justify;
  text-justify: inter-word;
}

#myimage{
  top:36%;
  left: 18%;
  transform: translateX(-50%);
  width:300px;
  height:300px;
  position:absolute;
  text-align: center;
  clip-path:circle(40%);
}

#myimage img{
  max-width:100%;
  transform: translateY(-5%);
}


#mainbottom{
  height:800px;
  width:80vw;
  border-radius: 15px;
  background-color:white;
  position: relative;
  overflow:hidden;
  padding-bottom: 300px; 
  margin-bottom: -300px;
  padding-left: 10vw; 
  margin-left: -10vw;
  z-index:0;
  opacity:1;
  font-family: 'Noto Sans JP', sans-serif;
}

#instruction{
  font-family: 'Bad Script', cursive;
  font-size:40px;
  left:400px;
  top:10%;
  z-index:6;
  position: absolute;

}

#main1 {
  height: 1000px;
  width: 80vw;
  border-radius: 15px;
  background-color: #dfd8ef;
  position: absolute;
  content: "";
  transform: rotate(36.85deg) translate(0,0);
  transform-origin: top left;
  z-index:1;
  transition:transform 2s, opacity 2s;
  overflow:hidden;
}

#main2 {
  height: 1000px;
  width: 80vw;
  border-radius: 15px;
  background-color: #efdfd8;
  position: absolute;
  content: "";
  transform:  rotate(36.85deg) translate(60px,80px);
  transform-origin: top left;
  z-index:2;
  transition:transform 2s, opacity 2s;
  overflow:hidden;
}

#main3 {
  height: 1000px;
  width: 80vw;
  border-radius: 15px;
  background-color: #dfd8ef;
  position: absolute;
  content: "";
  transform:  rotate(36.85deg) translate(120px,160px);
  transform-origin: top left;
  z-index:3;
  transition:transform 2s, opacity 2s;
  overflow:hidden;
}

#main4 {
  height: 1000px;
  width: 80vw;
  border-radius: 15px;
  background-color: #efdfd8;
  position: absolute;
  content: "";
  transform:  rotate(36.85deg) translate(180px,240px);
  transform-origin: top left;
  z-index:4;
  transition:transform 2s, opacity 2s;
  overflow:hidden;
}

#main5 {
  height: 1000px;
  width: 80vw;
  border-radius: 15px;
  background-color: #dfd8ef;
  position: absolute;
  content: "";
  transform:  rotate(36.85deg) translate(240px,320px);
  transform-origin: top left;
  z-index:5;
  transition:transform 2s, opacity 2s;
  overflow:hidden;
}

.header{
  padding-top:5px;
  padding-left:10px;
  font-size:40px;
  margin-left:50px;
  margin-right:50px;
  color:#303030;
  border-bottom: 1px solid #909090;
}

.mainlabel:hover{
  color:white;

}

.hint{
  float:right;
  opacity:0.5;
  right: 5%;
  opacity:0;
  transition:opacity 3s ease-in;
  z-index:6;
}

.hint:hover{
  color:white;
  cursor:pointer;
}


.hint2{
  top:6%;
  left:35%;
  font-family: 'Bad Script', cursive;
  opacity: 0;
  position:absolute;
}

.maincontent{
  height:800px;
  top:10%;
  left:10%;
  position:absolute;
  font-size:1vw;
  opacity:0;
  transition:opacity 2s ease-in;
  display: flex;
  flex-direction: row;
}

.container{
  width:450px;
  height:100%;
  padding:10px;
  padding-top:30px;
  margin-left:20px;
  margin-right:20px;
  border-radius:20px;
  border: 1pt solid grey;
  display: flex;
  box-shadow: 5px 5px 5px 5px #909090;
  flex-direction: column;
  font-family: 'Noto Sans JP', sans-serif;
}

.title{
  padding:10px;
  width:97%;
  font-size:40px;
  text-align: left;
  border-bottom:1px dotted grey;
}

.description{
  padding:10px;
  padding-top:20px;
  font-size:25px;
  text-align: justify;
  text-justify: inter-word;
}

.graph{
  padding:10px;
  padding-top:40px;
  object-fit: contain;
}

.graph img{
  border-radius:10px;
  max-width:100%;
  transition: opacity 0.5s ease;
}

.caption{
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
  margin-left:10px;
  margin-right:10px;
  margin-top:-135px;
  max-width:100%;
  opacity:0;
  padding-top:50px;
  padding-bottom:50px;
  font-size:15px;
  text-align: center;
  transition: opacity .5s ease;
  background-color: rgba(255, 255, 255, 0.8);
}

.author{
  padding:10px;
  padding-top:100px;
  color: #606060;
  font-style: italic;
  text-align:right;
}

#main5 #m5 .description{
  margin-top:30%;

}

.skillcontent{
  top:15%;
  left:10%;
  width: 75%;
  position:absolute;
  font-size:20px;
  opacity:0;
  transition:opacity 2s ease;
  display: flex;
  flex-direction: row;
}
.skills{
  width:50%;
}

.skills ul{
  list-style: none;
}

.skills ul li{
  padding-top:10px;
  padding-bottom:10px;
}

.notstars{
  color:white;
  float: right;
}

.stars{
  color:black;
  float: right;
}


.skills ul li:hover .stars{
  letter-spacing: 2px;
}

.navbar{
  height:1000px;
  width:10vw;
  z-index:0;
}

.sidebar{
  width:10vw;
  border-radius:15px;
  background-color:white;
  z-index:0;
  position:fixed;
  right:0;
  display: flex;
  flex-direction: column;
  font-family: Futura, 'Noto Sans JP', sans-serif;
  color:#909090;
}

.menu{
  font-size:2vw;
  text-align: right;
  margin-bottom: 10px;
  margin-right:5px;
}

.item{
  font-size:1.5vw;
  text-align: right;
  margin-top:9px;
  margin-right:5px;
}

.item:hover{
  background:#c1b4e0;
  cursor:pointer;
}

#closebutton{
  font-size:1.5vw;
  text-align: right;
  margin-top:9px;
  margin-right:5px;
  display:none
}

#closebutton:hover{
  background:#c1b4e0;
  cursor:pointer;
}

.foot{
  width:100%;
  height: 100px;
  opacity:0.8;
}

.ending{
  width:80%;
  margin:auto;
  text-align:center;
  font-size:25px;
}

#music{
  float:right;
  position:relative;
  max-width:660px;
}

#response{
  float:right;
  margin:30px;
  margin-right:50px;
  padding:10px;
  position:relative;
  text-align:center;
  font-size:30px;
  background:white;
  font-family: 'Bad Script', cursive;
}

#response,
#response::before,
#response::after {
  box-shadow: 1px 1px 1px rgba(0,0,0,0.25);
  border: 1px solid #bbb;
}

#response::before,
#response::after {
  content: "";
  position: absolute;
  height: 95%;
  width: 99%;
  background-color: #eee;
}

#response::before {
  right: 15px;
  top: 0;
  transform: rotate(-5deg);
  z-index: -1;
}

#response::after {
  top: -10px;
  right: -5px;
  transform: rotate(5deg);
  z-index: -2;
}

input,textarea{
  display:block;
  margin:auto;
  line-height:30px;
  width:300px;
  border:1px solid #606060;
  border-radius:5px;
  resize:none;
}

input[type="submit"]{
  width:100px;
  margin:auto;
  margin-top:20px;
  padding:5px;
  border:1px solid #606060;
  border-radius:10px;
  font-weight:300;
  color:#000000;
  text-align:center;
  transition: all 1s;
}

input[type="submit"]:hover{
   color:#FFFFFF;
   background-color:#000000;
}

#showform{
  right:-10px;
  top: 25px;
  height:60px;
  width:60px;
  z-index:7;
  background-color: #c1b4e0;
  position:absolute;
  border-top-left-radius:50px;
  border-bottom-left-radius:50px;
  font-size:40px;
  display:none;
}
#showform:hover{
  cursor: pointer;
}

@media (max-width:1000px){
  .navbar{
    display:none;
  }

  #menubutton{
    opacity:1;
  }

  #main{
    width:100vw;
    height:750px;
  }

  #back{
    width: 1000px;
  }

  div h1{
    font-size:60px;
  }

  div h3{
    font-size:25px;
  }

  .contactmethod{
    left: 0;
    transform: translateX(0);
    background-color: black;
    color: white;
  }

  #myimage{
    left: 25%;
    width:200px;
    height:200px;
  }
  #intro{
    left: 70%;
    font-size:20px;
    text-align:left;
  }
  #mainbottom{
    width:100vw;
    height:800px;
  }

  .header{
    margin-left:30px;
    margin-right:20px;
  }

  #instruction{
    left:300px;
    top:2%;
  }

  #main1,#main2,#main3,#main4,#main5 {
    width:100vw;
  }

  #intro{
    width:40%;
  }
  
  .sidebar{
    width: 100px;
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
  }

  .menu{
    font-size:30px;
  }
  .item{
    font-size:20px;
  }

  #closebutton{
    display:block;
    font-size:3vw;
  }
  .foot{
    padding-top:200px;
  }

  #music{
    z-index:6;
    display:none;
  }

  #response{
    z-index:6;
    display:none;
  }
  #response:before,#response:after{
    opacity:0;
  }

  #showform{
    display:block;
  }

}

@media (max-width:600px){
.container{
  width:100vw;
}

.hint2{
  left:9%;
}

#leftarrow{
  display:none;
}

#rightarrow{
  display:none;
}

#music{
  max-width:100%;
  margin:0;
}

#response{
  width:100%;
  margin:0;
}
#showform{
  right:-10px;
}
}
